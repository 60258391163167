<template>
    <custom-dialog
      :visible.sync="visible"
      :title="title"
      @close="colseDialog"
      @submit="submit"
      width="45%"
    >
      <div class="editTitle">公告基本信息</div>
  
      <div class="edit-main">
        <el-form
          :model="editForm"
          label-width="120px"
          :rules="rules"
          ref="editVersionForm"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属项目：" prop="projId">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  filterable
                  v-model="editForm.projId"
                  
                  placeholder="请选择所属项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公告标题：" prop="notiTitle">
                <el-input
                  v-model="editForm.notiTitle"
                  size="small"
                  placeholder="请输入公告标题"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发布人：" prop="notiOper">
                <el-input
                  v-model="editForm.notiOper"
                  size="small"
                  placeholder="请输入发布人"
                ></el-input>
              </el-form-item>
            </el-col>
  
          
            <el-col :span="24">
              <el-form-item label="公告内容：" prop="notiContent">
                <el-input
                  class="date"
                  v-model="editForm.notiContent"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入公告内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
           
          </el-row>
        </el-form>
      </div>
    </custom-dialog>
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  import basic from '../../api/modules/basic'
  
  export default {
    name: "notificationAnnouncementEdit",
    components: { CustomDialog },
    props: ["visible", "title",'item'],
    data() {
      return {
        editForm: {},
        rules: {
          notiTitle: [{ required: true, message: "请输入公告标题", trigger: "blur" }],
          projId: [
            { required: true, message: "请选择所属项目", trigger: "change" },
          ],
          notiOper: [{ required: true, message: "请输入发布人", trigger: "blur" }],
         
          notiContent: [
            { required: true, message: "请输入发布内容", trigger: "blur" },
          ],
        },
        uploadUrl: config.uploadUrl,
        equipType: [],
        projectList:[]
        // equipType: equipType
      };
    },
    watch:{
      item(val){
        if(val.notiId){
          this.editForm=val
        }else{
          this.editForm={
            notiTitle:'',
            projId:'',
            notiOper:'',
            notiContent:'',
          }
        }
      }
    },
    mounted(){
   
      this.getProjectList()
    },
    methods: {
      colseDialog() {
        this.$emit("close");
      },
      getProjectList(){
      basic.GetProjectList().then(res=>{
       
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
      submit() {
        this.$refs.editVersionForm.validate((valid) => {
          if (valid) {
            if(this.editForm.notiId){
              basic.updateEquipnotice(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
            }else{
              basic.addEquipnotice(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
            }
            
          }
        });
      },
   
    },
  };
  </script>
  
  <style scoped lang="less">
  .editTitle {
    border: 1px solid #eeeeee;
    border-bottom: 0px solid #eeeeee;
    padding: 10px 0 10px 30px;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
  }
  .edit-main {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
  }
  </style>
  