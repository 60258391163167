<template>
    <div class="pageMain">
      <div class="flex">
        <!-- 组织项目树 -->
  
        <OriginTree class="tree" :filterText="filterText" @checked="init">
          <template slot="search">
            <SearchLeft @search="search" @reset="reset"   
              info="项目信息"
              :iconShow="false">
              <el-form :inline="true" :model="searchForm">
                <el-form-item label="" class="formItem">
                  <el-input
                    v-model="filterText"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
        </OriginTree>
  
        <!--右边列表  -->
        <div class="form">
          <SearchHead
            @search="search"
            @reset="reset"
           
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="公告标题">
                <el-input
                  v-model="searchForm.notiTitle"
                  size="small"
                  placeholder="公告标题"
                ></el-input>
              </el-form-item>
              
           
            </el-form>
          </SearchHead>
          <CustomTables
            :data="tableData"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          
            :total="total"
            title="公告信息"
          >
            <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
               
                class="btn"
                >新建</el-button
              >
            </template>
           
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="notiTitle" label="公告标题" align="center">
            </el-table-column>
            <el-table-column prop="notiContent" label="公告内容" align="center">
            </el-table-column>
            <el-table-column prop="notiOper" label="发布单位" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="发布时间" align="center">
            </el-table-column>
           
            <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-view"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
            
          </CustomTables>
        </div>
      </div>
      <notificationAnnouncementEdit :visible.sync="showDialog" @close="colseDialog" :title="title"  @done="doneDialog" :item="item"></notificationAnnouncementEdit>
    </div>
  </template>
  
  <script>
  import OriginTree from "../../../components/common/OriginTree.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import notificationAnnouncementEdit from '../../../components/basic/notificationAnnouncementEdit.vue'
  import basic from "../../../api/modules/basic";
  export default {
    components: {
      OriginTree,
      SearchHead,
      CustomTables,
      SearchLeft,
      notificationAnnouncementEdit
    },
    name: "statistics",
    data() {
      return {
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        tableData: [
         
        ],
        options: [
        
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        showDialog:false,
        title:'',
        filterText:'',
        item:{}
      };
    },
    mounted() {},
    methods: {
      addFrom() {
        this.showDialog=true
        this.title='新增'
        this.item={}
      },
      init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.searchForm.currPage = 1;
      this.orgaId=val.orgaId;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
    getList() {
      basic.equipnoticeGetPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    doneDialog(){
        this.showDialog=false
        this.getList();
      },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      detailItem(item){
          //详情
          this.showDialog=true
        this.title='编辑公告'
        this.item=item
      },
      deleteItem(item){
        this.$confirm(`此操作将永久删除该公告, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        basic.deleteEquipnotice( item.notiId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
      },
      colseDialog(){
        this.showDialog=false
      },
      handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    },
  };
  </script>
  
  <style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail{
      color: #008f4d;
  }
  .delete{
    margin-left: 10px;
  }
  </style>
  