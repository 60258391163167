import api from '../httpServer'
import config from '../config'
// import store from '@/store/index'

const basic ={
    //分页查询公司列表
    selectCompanyList(data) {
        return api.get(`${config.headerUrl}company/selectCompanyList`,data)
    },
     //新增公司
     newCompany(data) {
        return api.post(`${config.headerUrl}company/newCompany`,data)
    },
      //编辑公司
      updateCompany(data) {
        return api.post(`${config.headerUrl}company/updateCompany`,data)
    },
     //删除公司
     delCompany(data) {
        return api.post(`${config.headerUrl}company/delCompany`,data)
    },
    // 省市区懒加载  省：{pid：0}   市 {pid: 省id}
    getAreaList(data) {
        return api.get(`${config.headerUrl}basicSet/GetAreaList`,data)
    },
    //分页查询商户管理信息
    getPage(data) {
        return api.get(`${config.headerUrl}basebusiness/getPage`,data)
    },
    //添加商户管理信息
    addBasebusiness(data) {
        return api.post(`${config.headerUrl}basebusiness/add`,data)
    },
     //编辑商户管理信息
     updateBasebusiness(data) {
        return api.post(`${config.headerUrl}basebusiness/update`,data)
    },
      //删除商户管理信息
      deleteBasebusiness(data) {
        return api.post(`${config.headerUrl}basebusiness/delete/${data}`,data)
    },

    //分页查询设备档案列表
    getBaseEquipListByPage(data) {
        return api.get(`${config.headerUrl}base/equip/getBaseEquipListByPage`,data)
    },
    //商户下拉框列表
    getBasebusinessList(data) {
        return api.get(`${config.headerUrl}basebusiness/getBasebusinessList`,data)
    },
    //新增设备档案
    addBaseEquip(data) {
        return api.post(`${config.headerUrl}base/equip/addBaseEquip`,data)
    },
    //编辑设备档案
    editBaseEquip(data) {
        return api.post(`${config.headerUrl}base/equip/editBaseEquip`,data)
    },
    //分页查询人员绑定列表
    getEquipOperatorListByPage(data) {
        return api.get(`${config.headerUrl}base/equip/operator/getEquipOperatorListByPage`,data)
    },
    //新增-选择设备列表
    getEquipList(data) {
        return api.get(`${config.headerUrl}base/equip/operator/getEquipList`,data)
    },
    //新增-选择人员列表
    getOperatorList(data) {
        return api.get(`${config.headerUrl}base/equip/operator/getOperatorList/${data}`,data)
    },
    //新增-根据设备查询已绑定的操作人
    getBingDingOperatorList(data) {
        return api.get(`${config.headerUrl}base/equip/operator/getBingDingOperatorList/${data}`,data)
    },
    //新增-绑定
    bingDing(data) {
        return api.post(`${config.headerUrl}base/equip/operator/bingDing`,data)
    },
    //新增-删除人员
    delOperator(data) {
        return api.get(`${config.headerUrl}base/equip/operator/delOperator/${data}`,data)
    },
    //分页查询驾驶人员
    operatorsGetPage(data) {
        return api.get(`${config.headerUrl}operators/getPage`,data)
    },
    //新增驾驶人员
    addOperatorsDriver(data) {
        return api.post(`${config.headerUrl}operators/add`,data)
    },
     //编辑驾驶人员
     updateOperatorsDriver(data) {
        return api.post(`${config.headerUrl}operators/update`,data)
    },
     //删除驾驶人员
     deleteOperatorDriver(data) {
        return api.post(`${config.headerUrl}operators/${data}`,data)
    },
     //分页查询项目信息
     GetProjectListByPage(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectListByPage`,data)
    },
    //添加项目信息
    AddProject(data) {
        return api.post(`${config.headerUrl}basicSet/AddProject`,data)
    },
      //编辑项目信息
      updateProject(data) {
        return api.post(`${config.headerUrl}basicSet/updateProject`,data)
    },
     //批量删除项目信息
     delProject(data) {
        return api.post(`${config.headerUrl}basicSet/delProject`,data)
    },
    //获取项目选择列表
    GetProjectList(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectList`,data)
    },
    //设备启用分页查询
    deviceUseGetPage(data) {
        return api.get(`${config.headerUrl}device/use/getPage`,data)
    },
    //绑定监控
    binding(data) {
        return api.post(`${config.headerUrl}device/use/binding`,data)
    },
    //获取终端号下拉框
    terminalGetList(data) {
        return api.get(`${config.headerUrl}device/terminal/getList`,data)
    },
    //获取终端号下拉框
    getBingList(data) {
        return api.get(`${config.headerUrl}device/terminal/getBingList`,data)
    },
    //解绑监控
    Unbind(data) {
        return api.post(`${config.headerUrl}device/use/Unbind`,data)
    },
    //设备拆卸分页查询
    dismantleGetPage(data) {
        return api.get(`${config.headerUrl}equip/dismantle/getPage`,data)
    },
    //设备拆卸新增
    addDismantle(data) {
        return api.post(`${config.headerUrl}equip/dismantle/add`,data)
    },
     //设备拆卸编辑
     updateDismantle(data) {
        return api.post(`${config.headerUrl}equip/dismantle/update`,data)
    },
    //删除设备拆卸信息
    delDismantle(data) {
        return api.post(`${config.headerUrl}equip/dismantle/delete/${data}`,data)
    },
    //分页查询通知公告
    equipnoticeGetPage(data) {
        return api.get(`${config.headerUrl}equipnotice/getPage`,data)
    },
      //添加通知公告
      addEquipnotice(data) {
        return api.post(`${config.headerUrl}equipnotice/add`,data)
    },
     //更新通知公告
     updateEquipnotice(data) {
        return api.post(`${config.headerUrl}equipnotice/update`,data)
    },
    //删除通知公告
    deleteEquipnotice(data) {
        return api.post(`${config.headerUrl}equipnotice/delete/${data}`,data)
    },
    //分页查询设备参数列表
    getListByPage(data) {
        return api.get(`${config.headerUrl}v1/device/param/getListByPage`,data)
    },
    //查询设备参数设置
    deviceParam(data) {
        return api.get(`${config.headerUrl}v1/device/param/deviceParam/${data}`,data)
    },
    //设备参数保存
    updateDeviceParam(data) {
        return api.post(`${config.headerUrl}v1/device/param/updateDeviceParam`,data)
    },
    //查询设备档案列表(设备启用)
    getBaseEquipListAll(data) {
        return api.get(`${config.headerUrl}base/equip/getBaseEquipListAll`,data)
    },
}

export default basic